<template>
  <div class="slider-container">
    <div class="slider" :style="sliderStyle">
      <div class="slide">
         <div
                        
                          :class="
                            quiz.id == selectedTemplateId
                              ? 'selected-template'
                              : null
                          "
                          style="
                            box-shadow: 0px 4px 10px rgba(10, 4, 17, 0.15);
                            border-bottom-left-radius: 12px;
                            border-bottom-right-radius: 12px;
                          "
                        >
                          <LazyImage
                            :src="ImageSrc(quiz.image)"
                            :key="quiz.image"
                            alt=""
                            style="width: 100%; height: 70%"
                          />

                          <div class="row m-0">
                            <div class="col-12">
                              <div class="py-2" style="">
                                <div class="">
                                  <p
                                    class="font-weight-bold"
                                    style="font-size: 16px; margin-bottom: 5px"
                                  >
                                    {{ quiz.name }}
                                  </p>
                                </div>
                                <div>
                                  <p
                                    class=""
                                    style="
                                      font-size: 12px;
                                      margin-bottom: 10px;
                                      overflow: auto;
                                    "
                                  >
                                    {{ quiz.description }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class=" ">
                            <div
                              class="d-flex flex-row flex-md-column flex-lg-row   px-3 pb-2"
                            >
                              <button
                                @click="SelectTemplate(quiz)"
                                class="outline-none text-white outline-none border-0 mx-1 w-100 px-4 py-2 choose-btn "
                                style=""
                              >
                                Choose
                              </button>
                              <!-- <AddQuiz :selectedQuiz="quiz"/> -->
                              <button
                                v-if="quiz.quizKey"
                                @click.stop="OpenPreview(quiz)"
                                class="outline-none text-white outline-none border-0 w-100 mx-1 px-3 py-2 see-demo-btn mt-0 mt-md-2 mt-lg-0 "
                                style=""
                              >
                                Preview
                                <b-icon-eye class="ml-2"></b-icon-eye>
                              </button>
                            </div>
                          </div>
                        </div>
      </div>
    </div>
    <button @click="prevSlide">Prev</button>
    <button @click="nextSlide">Next</button>
  </div>
</template>

<script>
export default {
  props: ['slides'],
  data() {
    return {
      currentSlide: 0,
    };
  },
  computed: {
    sliderStyle() {
      return {
        transform: `translateX(-${100 * this.currentSlide}%)`,
      };
    },
  },
  methods: {
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.slides.length;
    },
    prevSlide() {
      this.currentSlide = this.currentSlide - 1 < 0 ? this.slides.length - 1 : this.currentSlide - 1;
    },
  },
};
</script>

<style scoped>
/* Add your slider CSS here */
.slider-container {
  overflow: hidden;
}
.slider {
  display: flex;
  transition: transform 0.3s ease-in-out;
}
.slide {
  flex: 0 0 auto;
  width: calc(100% / 3); /* Adjust based on slidesToShow */
}
</style>
